import React from 'react';

interface ProtectedHandlerProps {
    [key: string]: any;
}

const ProtectedHandler: React.FC<ProtectedHandlerProps> = (props) => {

    //no user login

    return <props.screenProps.routeParams.component {...props} />

};

export default ProtectedHandler;