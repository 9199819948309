import React from 'react';
import { UiView } from '@dex/bubl-ui';

import styles from './Container.module.scss';

interface ContainerProps {
    [key: string]: any;
}

const Container: React.FC<ContainerProps> = (props) => {

    const { size } = props;

    const containerClasses = [styles.container];

    if (size === "box") containerClasses.push(styles.box);

    return (
        <UiView className={containerClasses}>
            {props.children}
        </UiView>
    )
}

export default Container;