import { Events, UiFieldSelect, UiLink, UiView, useForm } from "@dex/bubl-ui";
import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Container from "../../elements/Container/Container";

const ModelReview = React.lazy(() => import('../../elements/ModelReview/ModelReview'));
const ModelCanvas = React.lazy(() => import('../../elements/ModelCanvas/ModelCanvas'));

import styles from "./ReviewClothesScreen.module.scss";

const ReviewClothesScreen: React.FC<ReviewClothesScreenProps> = (props) => {

    const modelRef = useRef();

    const form = useForm({ model: "Wrestler", gender: "Male", activity: "Run", exogen: "full", version: process.env.REACT_APP_AVATAR_VERSION });

    const gender = form.values.gender.toLowerCase();

    const male = {
        hair: {
            color: "#333333",
            type: "05"
        },
        skin: "#CF9E7C",
        brows: {
            color: "#333333",
            type: "03"
        },
        headgear: {
            color: "#0099ff",
            type: "00"
        },
        eyewear: {
            color: "#0099ff",
            type: "00"
        },
        beard: {
            color: "#22ee66",
            type: "00"
        },
        jaw: {
            type: "01"
        },
        eyes: {
            type: "00",
            color: "#3d251e"
        },
        lips: {
            type: "03",
            color: "#b08568"
        },
        nose: {
            type: "02"
        },
        features: {
            cheeks: "01",
            // conture: "01",
        }
    };

    const female = {
        hair: {
            color: "#333333",
            type: "08"
        },
        skin: "#CF9E7C",
        brows: {
            color: "#333333",
            type: "03"
        },
        headgear: {
            color: "#0099ff",
            type: "00"
        },
        eyewear: {
            color: "#0099ff",
            type: "00"
        },
        beard: {
            color: "#22ee66",
            type: "00"
        },
        jaw: {
            type: "01"
        },
        eyes: {
            type: "00",
            color: "#3d251e"
        },
        lips: {
            type: "01",
            color: "#BB2013"
        },
        nose: {
            type: "02"
        },
        features: {
            // cheeks: "01",
            conture: "01",
        }
    };

    let config = {
        gender: gender,
        clothes: [],
        suit: [],
        preload: [],
    };

    if (gender === "male") config = { ...config, ...male };
    else if (gender === "female") config = { ...config, ...female };

    useEffect(() => {

        const activityMap: any = {
            "Run": ["Shoe", "Shorts", "Tshirt"],
            "Sprint": ["Shoe", "Leggins", "Tshirt"],
            "Combat": gender === "male" ? ["Shoe", "Shorts", "Tshirt"] : ["Shoe", "Leggins", "Tshirt"],
            "Fitness": gender === "male" ? ["Shoe", "Shorts", "Tshirt"] : ["Shoe", "Leggins", "Tshirt"],
            "Team": ["Shoe", "Shorts", "Tshirt"],
            "Golf": gender === "male" ? ["Shoe", "GolfShorts", "Polo"] : ["Shoe", "Skirt", "Polo"],
        }

        config.clothes = [];  //activityMap[form.values.activity];

        const suitMap: any = {
            "Run": ["Calf", "Shorts"],
            "Sprint": ["Calf", "Shorts", "Forearm"],
            "Combat": ["Fullarm", "Calf"],
            "Fitness": ["Top", "Forearm", "Upperarm", "Calf"],
            "Team": ["Calf", "Shorts"],
            "Golf": ["Forearm", "Upperarm", "Calf"],
            "Full": ["Forearm", "Upperarm", "Calf", "Shorts", "Top"],
        }

        if (form.values.exogen === "full") {

            config.suit = suitMap["Full"];

        } else if (form.values.exogen === "partial") {

            config.suit = suitMap[form.values.activity];

        } else if (form.values.exogen === "off") {

            config.suit = [];

        }

        Events.emit("config", config);

    }, [form.values.gender, form.values.activity, form.values.exogen])

    const handleZoom = useCallback((inOut: any) => {

        console.log(inOut);

        if (inOut === "-") Events.emit("camera", "zoomOut");
        else if (inOut === "+") Events.emit("camera", "zoomIn");

    }, []);

    return useMemo(() => (

        <Container>

            <UiView
                className={styles.select}
            >

                {/* <UiFieldSelect
                    form={form}
                    name={"version"}
                    defaultValue={form.defaults.version}
                    options={[
                        { value: "v10", label: "Latest" },
                    ]}
                    isClearable={false}
                /> */}

                <UiFieldSelect
                    form={form}
                    name={"gender"}
                    defaultValue={form.defaults.gender}
                    options={[
                        { value: "Female", label: "Female" },
                        { value: "Male", label: "Male" },
                    ]}
                    isClearable={false}
                />

                <UiFieldSelect
                    form={form}
                    name={"model"}
                    defaultValue={form.defaults.model}
                    options={[
                        { value: "Runner", label: "Runner" },
                        { value: "Golfer", label: "Golfer" },
                        { value: "Sprinter", label: "Sprinter" },
                        { value: "Swimmer", label: "Swimmer" },
                        { value: "Wrestler", label: "Wrestler" },
                    ]}
                    isClearable={false}
                />

                <UiFieldSelect
                    form={form}
                    name={"activity"}
                    placeholder={"Activity"}
                    defaultValue={form.defaults.activity}
                    options={[
                        { value: "Run", label: "Run" },
                        { value: "Sprint", label: "Sprint" },
                        { value: "Combat", label: "Combat" },
                        { value: "Fitness", label: "Fitness" },
                        { value: "Golf", label: "Golf" },
                        { value: "Team", label: "Team" },
                    ]}
                    isClearable={false}
                />

                <UiFieldSelect
                    form={form}
                    name={"exogen"}
                    placeholder={"Exogen"}
                    defaultValue={form.defaults.exogen}
                    options={[
                        { value: "off", label: "Suit Off" },
                        { value: "full", label: "Full Suit" },
                        { value: "partial", label: "Partial Suit" },
                    ]}
                    isClearable={false}
                />

            </UiView>

            <UiLink className={styles.zoomIn} onClick={handleZoom.bind(null, '+')}>+</UiLink>
            <UiLink className={styles.zoomOut} onClick={handleZoom.bind(null, '-')}>-</UiLink>

            <UiView
                className={styles.canvas}
            >

                <Suspense fallback={<></>}>

                    <ModelCanvas
                        controls={{
                            enablePan: true
                        }}
                        shadows={{
                            far: 3,
                            scale: 8,
                            rotation: [Math.PI / 2, 0, 0],
                            position: [0, -3, 0],
                        }}
                        position={form.values.camera ? form.values.camera.split(",") : undefined}
                        lights={true}
                        initialCamera={"full"}
                    >

                        {form.values.model && form.values.gender && form.values.version &&
                            <ModelReview
                                modelRef={modelRef}
                                path={'/model/full/' + form.values.version + '/' + form.values.gender + "-" + form.values.model + '.glb'}
                                position={[0, -3, 0]}
                                rotation={[0, 0, 0]}
                                scale={3.6}
                                config={config}
                                loadItems={false}
                                // loadAnimations={true}
                                // pose={"idle"}
                                loads={{
                                    enabled: true,
                                    editor: "shorts_left_front",
                                    suit: {
                                        // calf_left_inner: [
                                        //     { "faceIndex": 716, "weight": 100, "position": { "x": 0.048687569404174125, "y": -0.13307040741203524, "z": -0.23716144648662021 }, "rotation": { "_x": 2.5494516726717884, "_y": -1.2183071347508543, "_z": 2.5784735418505518, "_order": "XYZ" }, "uv": { "x": 0.43977072228053604, "y": 0.8639447114497568 }, "key": "2849471de4eca7c009588234171047a40b5a8c31" },
                                        //     { "faceIndex": 7772, "weight": 100, "position": { "x": -0.39514494342904205, "y": -2.106067913751265, "z": -0.3421101246078789 }, "rotation": { "_x": 2.8072801145415998, "_y": 0.8352366721496159, "_z": -2.889525608635787, "_order": "XYZ" }, "uv": { "x": 0.9915999169493896, "y": 0.5841252539627865 }, "key": "e3f5f3c2f5bd9d63f79deb097dde886de8b2a259" },
                                        //     { "faceIndex": 3185, "weight": 100, "position": { "x": 0.36084370761112167, "y": -1.8472622942682722, "z": -0.38620663616198136 }, "rotation": { "_x": 2.84400425147809, "_y": -0.8502380164404721, "_z": 2.915084803774235, "_order": "XYZ" }, "uv": { "x": 0.004391331613878358, "y": 0.3871231970239712 }, "key": "ac723e0d3334b1fd1b532fd8279e02e275ca0f9b" },
                                        // ],
                                    },
                                }}
                            />
                        }

                    </ModelCanvas>

                </Suspense>

            </UiView>

        </Container >

    ), [form.values.gender, form.values.model]);

};

interface ReviewClothesScreenProps {
    [key: string]: any;
}

export default ReviewClothesScreen;
