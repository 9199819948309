import { titleCase } from "@dex/bubl-helpers";
import { UiButton, UiLink, UiSpace, UiView } from "@dex/bubl-ui";
import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import Button from "../../elements/Button/Button";
import Container from "../../elements/Container/Container";
import postMessage from "../../functions/postMessage";
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';

const ModelView = React.lazy(() => import('../../elements/ModelView/ModelView'));
const ModelCanvas = React.lazy(() => import('../../elements/ModelCanvas/ModelCanvas'));

const SaveScreen: React.FC<SaveScreenProps> = (props) => {

    const [bodyType, setBodyType] = useState('male-runner'); //'male-runner'
    const [config, setConfig] = useState();
    const [camera, setCamera] = useState("face");

    const modelRef: any = useRef();
    const exporter = new GLTFExporter();

    useEffect(() => {

        postMessage.connect();

        const unsubscribe: any = postMessage.listen("buildMe", (data: any) => {

            if (data && !data.type) return;

            if (data.bodyType) {
                setBodyType(data.bodyType);
            }

            if (data.config) {
                setConfig(data.config);
            }

            if (data.camera) {
                setCamera(data.camera);
            }

        });

        return () => {

            unsubscribe();

        }

    }, []);

    const version = "v6";

    const modelPath = 'review/' + version + '/Avatar_' + titleCase(bodyType.replace('-', ' ')).replace(" ", "_") + '.glb';

    const generateGLB = () => {

        exporter.parse(

            modelRef.current,

            function (result) {

                if (result instanceof ArrayBuffer) {

                    saveArrayBuffer(result, 'scene.glb');

                } else {

                    const output = JSON.stringify(result, null, 2);

                    console.log(output);

                    saveString(output, 'scene.gltf');

                }

            },
            function (error) {

                console.log('An error happened during parsing', error);

            },

            {
                binary: true,
                maxTextureSize: 10,
            }

        );

    }

    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link); // Firefox workaround, see #6594

    const save = (blob: any, filename: any) => {

        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();

        // URL.revokeObjectURL( url ); breaks Firefox...

    }

    const saveString = (text: any, filename: any) => {

        save(new Blob([text], { type: 'text/plain' }), filename);

    }


    const saveArrayBuffer = (buffer: any, filename: any) => {

        save(new Blob([buffer], { type: 'application/octet-stream' }), filename);

    }



    return useMemo(() => (

        <>
            <UiSpace height={50} />

            <UiView
                onClick={generateGLB}>
                <Button
                    label={"GENERATE GLB"}
                    block={true}
                    type={"submit"}
                    theme={"primary"}

                />
            </UiView>


            <UiSpace height={50} />


            <Container>

                {/* <pre>
                {JSON.stringify(config, null, 4)}
            </pre> */}

                <Suspense fallback={<></>}>

                    <ModelCanvas
                        // stats
                        lights
                        controls={{
                            enablePan: true
                        }}
                        camera={camera}
                        setCamera={setCamera}
                        shadows={{
                            far: 3,
                            scale: 8,
                            rotation: [Math.PI / 2, 0, 0],
                            position: [0, -3, 0],
                        }}
                    >

                        {bodyType &&
                            <ModelView
                                modelRef={modelRef}
                                path={'/models/' + modelPath}
                                position={[0, -3, 0]}
                                scale={3.5}
                                config={config}
                                loadItems={true}
                            />
                        }

                    </ModelCanvas>

                </Suspense>

            </Container>

        </>
    ), [config, bodyType, camera]);

};

interface SaveScreenProps {
    [key: string]: any;
}

export default SaveScreen;
