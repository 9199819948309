import React, { Suspense, useMemo } from "react";

import Container from "../../elements/Container/Container";

const ModelBuild = React.lazy(() => import('../../elements/ModelBuild/ModelBuild'));
const ModelCanvas = React.lazy(() => import('../../elements/ModelCanvas/ModelCanvas'));

const BuildScreen: React.FC<BuildScreenProps> = (props) => {

    return useMemo(() => (

        <Container size={"box"}>

            <Suspense fallback={<></>}>

                <ModelCanvas
                    // stats
                    lights
                    controls={{
                        enablePan: true,
                        disableZoom: false
                    }}
                    initialCamera={"face"}
                    screenshot={true}
                >

                    <ModelBuild />

                </ModelCanvas>

            </Suspense>

        </Container>

    ), []);

};

interface BuildScreenProps {
    [key: string]: any;
}

export default BuildScreen;
