import React, { Suspense, useEffect, useRef, useState } from "react";
import Container from "../../elements/Container/Container";

const ModelChoose = React.lazy(() => import('../../elements/ModelChoose/ModelChoose'));
const ModelCanvas = React.lazy(() => import('../../elements/ModelCanvas/ModelCanvas'));

const ChooseScreen: React.FC<ChooseScreenProps> = (props) => {

    return (

        <Container>

            <Suspense fallback={<></>}>

                <ModelCanvas
                    // lights={true}
                    // stats={props.stats}
                    // shadows={{
                    //     far: 3,
                    //     scale: 6,
                    //     blur: 1.2,
                    //     rotation: [Math.PI / 2, 0, 0],
                    //     position: [0, -3, 0],
                    //     opacity: .3
                    // }}

                    controls={{
                        enablePan: true
                    }}
                    shadows={{
                        far: 3,
                        scale: 8,
                        rotation: [Math.PI / 2, 0, 0],
                        position: [0, -3, 0],
                    }}
                    lights={true}
                    initialCamera={"full"}
                >

                    <ModelChoose />

                </ModelCanvas>

            </Suspense>

        </Container>

    );
};

interface ChooseScreenProps {
    [key: string]: any;
}

export default ChooseScreen;
