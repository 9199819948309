import React from "react";
import { UiLink, UiView } from "@dex/bubl-ui";
import Container from "../../elements/Container/Container";

const HomeScreen: React.FC<HomeScreenProps> = (props) => {

    return (

        <Container>

            <UiView>
                <UiLink path="/build">Build Me</UiLink>
            </UiView>

            <UiView>
                <UiLink path="/choose">Choose Me</UiLink>
            </UiView>

            <UiView>
                <UiLink path="/see">See Me</UiLink>
            </UiView>

            {process.env.NODE_ENV === "development" &&
                <>

                    <UiView>
                        <UiLink path="/demo">Demo</UiLink>
                    </UiView>

                    <UiView>
                        <UiLink path="/save">Save</UiLink>
                    </UiView>
                </>
            }

            {(process.env.REACT_APP_REVIEW || process.env.NODE_ENV === "development") &&
                <>

                    <UiView>
                        <UiLink path="/editor">Load Editor</UiLink>
                    </UiView>

                    <UiView>
                        <UiLink path="/review">Review Models</UiLink>
                    </UiView>

                    <UiView>
                        <UiLink path="/review/clothes">Review Outfits</UiLink>
                    </UiView>

                    <UiView>
                        <UiLink path="/example">Test Weight Loading</UiLink>
                    </UiView>

                </>
            }

        </Container>

    );
};

interface HomeScreenProps {
    [key: string]: any;
}

export default HomeScreen;
