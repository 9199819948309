import React, { Suspense } from "react";
import Container from "../../elements/Container/Container";

const ModelView = React.lazy(() => import('../../elements/ModelView/ModelView'));
const ModelCanvas = React.lazy(() => import('../../elements/ModelCanvas/ModelCanvas'));

const ModelScreen: React.FC<ModelScreenProps> = (props) => {

    return (

        <Container>

            <Suspense fallback={<></>}>

                <ModelCanvas
                    stats
                    lights
                    controls
                    shadows={{
                        far: 3,
                        scale: 8,
                        rotation: [Math.PI / 2, 0, 0],
                        position: [0, -3, 0],
                    }}
                >

                    <ModelView
                        path={'/models/wraith-gltf/wraith.gltf'}
                        position={[0, -3, 0]}
                        scale={.09}
                        pose={"idle"}
                    />

                </ModelCanvas>

            </Suspense>

        </Container>

    );

};

interface ModelScreenProps {
    [key: string]: any;
}

export default ModelScreen;
