import { UiFieldSelect, UiView, useForm } from "@dex/bubl-ui";
import React, { Suspense, useMemo, useRef } from "react";
import Container from "../../elements/Container/Container";

const ModelView = React.lazy(() => import('../../elements/ModelView/ModelView'));
const ModelCanvas = React.lazy(() => import('../../elements/ModelCanvas/ModelCanvas'));

import styles from "./ReviewScreen.module.scss";
import { get } from "@dex/bubl-helpers";

const ReviewScreen: React.FC<ReviewScreenProps> = (props) => {

    const modelRef = useRef();

    const isTesting = get(props, 'screenProps.routeParams.testing');

    let testing = {};

    if (isTesting === "skin") testing = { skin: "#CF9E7C", metalness: "0", roughness: "0.5" };

    const form = useForm({ model: "Runner", gender: "Male", version: 'v8', ...testing });

    const colors = [
        "#FEC0A0", "#EACBA8", "#EED0B9", "#F7DDC5", "#F6E2AA",
        "#EEC793", "#F0BF8A", "#E3BB8F", "#ECBF83", "#CF9E7C",
        "#CA9661", "#AB8B64", "#94623C", "#895532", "#77441F",
        "#B16A48", "#804A2A", "#623A19", "#563A25", "#2F1D10"
    ];


    return useMemo(() => (

        <Container>

            <UiView
                className={styles.select}
            >
                <UiFieldSelect
                    form={form}
                    name={"version"}
                    defaultValue={form.defaults.version}
                    options={[
                        { value: "v8", label: "Latest" },
                        { value: "v7", label: "Version 7" },
                        // { value: "v6", label: "Version 6" },
                        // { value: "v5", label: "Version 5" },
                        // { value: "v4", label: "Version 4" },
                        // { value: "v3", label: "Version 3" },
                        // { value: "v2", label: "Version 2" },
                        // { value: "v1", label: "Version 1" },
                    ]}
                    isClearable={false}
                />
                <UiFieldSelect
                    form={form}
                    name={"gender"}
                    defaultValue={form.defaults.gender}
                    options={[
                        { value: "Female", label: "Female" },
                        { value: "Male", label: "Male" },
                    ]}
                    isClearable={false}
                />
                <UiFieldSelect
                    form={form}
                    name={"model"}
                    defaultValue={form.defaults.model}
                    options={[
                        { value: "Runner", label: "Runner" },
                        { value: "Golfer", label: "Golfer" },
                        { value: "Sprinter", label: "Sprinter" },
                        { value: "Swimmer", label: "Swimmer" },
                        { value: "Wrestler", label: "Wrestler" },
                    ]}
                    isClearable={false}
                />
            </UiView>

            {isTesting === "skin" &&
                <UiView
                    className={styles.select}
                >
                    <UiFieldSelect
                        form={form}
                        name={"skin"}
                        label={"Skin"}
                        defaultValue={form.defaults.skin}
                        allowCustomOption={true}
                        options={colors.map(color => { return { value: color, label: color } })}
                        isClearable={false}
                    />
                    <UiFieldSelect
                        form={form}
                        name={"roughness"}
                        label={"Roughness"}
                        defaultValue={form.defaults.roughness}
                        allowCustomOption={true}
                        options={[
                            { value: "0.0", label: "0.0" },
                            { value: "0.1", label: "0.1" },
                            { value: "0.2", label: "0.2" },
                            { value: "0.3", label: "0.3" },
                            { value: "0.4", label: "0.4" },
                            { value: "0.5", label: "0.5" },
                            { value: "0.6", label: "0.6" },
                            { value: "0.7", label: "0.7" },
                            { value: "0.8", label: "0.8" },
                            { value: "0.9", label: "0.9" },
                            { value: "1.0", label: "1.0" },
                        ]}
                        isClearable={false}
                    />
                    <UiFieldSelect
                        form={form}
                        name={"metalness"}
                        label={"Metalness"}
                        defaultValue={form.defaults.metalness}
                        allowCustomOption={true}
                        options={[
                            { value: "0.0", label: "0.0" },
                            { value: "0.1", label: "0.1" },
                            { value: "0.2", label: "0.2" },
                            { value: "0.3", label: "0.3" },
                            { value: "0.4", label: "0.4" },
                            { value: "0.5", label: "0.5" },
                            { value: "0.6", label: "0.6" },
                            { value: "0.7", label: "0.7" },
                            { value: "0.8", label: "0.8" },
                            { value: "0.9", label: "0.9" },
                            { value: "1.0", label: "1.0" },
                        ]}
                        isClearable={false}
                    />

                    <UiView className={styles.skinSwatch} style={{ backgroundColor: form.values.skin }}>
                    </UiView>

                </UiView>
            }

            <UiView
                className={styles.canvas}
            >

                <Suspense fallback={<></>}>

                    <ModelCanvas
                        controls={{
                            enablePan: true
                        }}
                        shadows={{
                            far: 3,
                            scale: 8,
                            rotation: [Math.PI / 2, 0, 0],
                            position: [0, -3, 0],
                        }}
                        position={form.values.camera ? form.values.camera.split(",") : undefined}
                        lights={true}
                        initialCamera={"full"}
                    >

                        {form.values.model && form.values.gender && form.values.version &&
                            <ModelView
                                modelRef={modelRef}
                                path={'/models/review/' + form.values.version + '/Avatar_' + form.values.gender + "_" + form.values.model + '.glb'}
                                position={[0, -3, 0]}
                                rotation={[0, 0, 0]}
                                scale={3.6}
                                config={{ skin: form.values.skin, metalness: form.values.metalness, roughness: form.values.roughness }}
                                loadAnimations={false}
                                loadItems={true}
                            />
                        }

                    </ModelCanvas>

                </Suspense>

            </UiView>

        </Container>

    ), [form]);

};

interface ReviewScreenProps {
    [key: string]: any;
}

export default ReviewScreen;
