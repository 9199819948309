import { Events, UiCol, UiFieldSelect, UiLink, UiRow, UiView, useForm } from "@dex/bubl-ui";
import React, { Suspense, useCallback, useEffect, useMemo, useRef } from "react";
import Container from "../../elements/Container/Container";

const ModelEditor = React.lazy(() => import('../../elements/ModelEditor/ModelEditor'));
const ModelCanvas = React.lazy(() => import('../../elements/ModelCanvas/ModelCanvas'));
const ModelEditorFields = React.lazy(() => import('../../elements/ModelEditor/ModelEditorFields'));

import styles from "./LoadEditorScreen.module.scss";

const LoadEditorScreen: React.FC<LoadEditorScreenProps> = (props) => {

    const modelRef = useRef();

    const form = useForm({ model: "Wrestler", gender: "Male", exogen: "top", zone: "upper_left_front", version: process.env.REACT_APP_AVATAR_VERSION });

    const gender = form.values.gender.toLowerCase();

    let config = {
        gender: gender,
        suit: ["Forearm", "Upperarm", "Calf", "Shorts", "Top", "Fullarm"],
    };

    useEffect(() => {

        Events.emit("camera", "suit_" + form.values.exogen);

    }, [form.values.exogen]);

    useEffect(() => {

        Events.emit("loading_zone", form.values.zone);

        Events.emit("camera", "suit_" + form.values.exogen + "_" + form.values.zone);

    }, [form.values.zone]);

    const onLoad = useCallback(() => {

        setTimeout(() => {

            Events.emit("camera", "suit_" + form.values.exogen + "_" + form.values.zone);

        }, 500);

    }, []);

    const zones = useMemo(() => {

        let zones: any = [];

        switch (form.values.exogen) {
            case "calf":
                zones = [
                    { value: "left_front", label: "Left Front" },
                    { value: "left_back", label: "Left Back" },
                    { value: "left_inner", label: "Left Inner" },
                    { value: "left_outer", label: "Left Outer" },
                ]
                break;
            case "shorts":
                zones = [
                    { value: "left_front", label: "Left Front" },
                    { value: "left_back", label: "Left Back" },
                    { value: "left_inner", label: "Left Inner" },
                    { value: "left_outer", label: "Left Outer" },
                    { value: "hip_front", label: "Hip Front" },
                    { value: "hip_back", label: "Hip Back" },
                    { value: "hip_left", label: "Hip Left" },
                    { value: "hip_right", label: "Hip Right" },
                ]
                break;
            case "top":
                zones = [
                    { value: "upper_left_front", label: "Upper Left Front" },
                    { value: "upper_left_back", label: "Upper Left Back" },
                    { value: "lower_left_front", label: "Lower Left Front" },
                    { value: "lower_left_back", label: "Lower Left Back" },
                    { value: "lower_left_side", label: "Lower Left Side" },
                    { value: "shoulder_left", label: "Shoulder Left" },
                    { value: "shoulder_right", label: "Shoulder Right" },
                ]
                break;
            case "forearm": case "fullarm": case "upperarm":
                zones = [
                    { value: "left_front", label: "Left Front" },
                    { value: "left_back", label: "Left Back" },
                    { value: "left_inner", label: "Left Inner" },
                    { value: "left_outer", label: "Left Outer" },
                ]
                break;

        }

        return zones;

    }, [form.values.exogen]);

    return useMemo(() => (

        <Container>

            <UiView
                className={styles.select}
            >

                <UiFieldSelect
                    form={form}
                    name={"gender"}
                    defaultValue={form.defaults.gender}
                    options={[
                        { value: "Female", label: "Female" },
                        { value: "Male", label: "Male" },
                    ]}
                    isClearable={false}
                />

                <UiFieldSelect
                    form={form}
                    name={"model"}
                    defaultValue={form.defaults.model}
                    options={[
                        { value: "Runner", label: "Runner" },
                        { value: "Golfer", label: "Golfer" },
                        { value: "Sprinter", label: "Sprinter" },
                        { value: "Swimmer", label: "Swimmer" },
                        { value: "Wrestler", label: "Wrestler" },
                    ]}
                    isClearable={false}
                />

                <UiFieldSelect
                    form={form}
                    name={"exogen"}
                    placeholder={"Exogen"}
                    defaultValue={form.defaults.exogen}
                    options={[
                        { value: "calf", label: "Calf" },
                        { value: "shorts", label: "Shorts" },
                        { value: "top", label: "Top" },
                        { value: "forearm", label: "Forearm" },
                        { value: "fullarm", label: "Fullarm" },
                        { value: "upperarm", label: "Upperarm" },
                    ]}
                    isClearable={false}
                />

                <UiFieldSelect
                    form={form}
                    name={"zone"}
                    placeholder={"Zone"}
                    defaultValue={form.defaults.zone}
                    options={zones}
                    isClearable={false}
                />

            </UiView>

            <UiView
                className={styles.canvas}
            >

                <Suspense fallback={<></>}>

                    <ModelCanvas
                        controls={{
                            enablePan: true
                        }}
                        shadows={{
                            far: 3,
                            scale: 8,
                            rotation: [Math.PI / 2, 0, 0],
                            position: [0, -3, 0],
                        }}
                        position={form.values.camera ? form.values.camera.split(",") : undefined}
                        lights={false}
                        initialCamera={form.values.exogen ? "suit" + form.values.exogen : "full"}
                    >

                        <ambientLight
                            intensity={1.5}
                        />

                        {form.values.model && form.values.gender && form.values.version && form.values.exogen &&
                            <ModelEditor
                                key={form.values.model + form.values.gender + form.values.version + form.values.exogen}
                                modelRef={modelRef}
                                path={'/model/item/suit_' + form.values.exogen + '/' + form.values.version + '/' + form.values.gender + "-" + form.values.model + '.glb'}
                                position={[0, -3, 0]}
                                rotation={[0, 0, 0]}
                                scale={3.6}
                                config={config}
                                onLoad={onLoad}
                                loads={{
                                    enabled: true,
                                    editor: form.values.exogen + "_" + form.values.zone,
                                    exogen: form.values.exogen,
                                    zone: form.values.zone,
                                    suit: {},
                                }}
                            />
                        }

                    </ModelCanvas>

                    <UiView className={styles.fields}>

                        <ModelEditorFields
                            exogen={form.values.exogen}
                            zone={form.values.zone}
                        />

                    </UiView>

                </Suspense>

            </UiView>


        </Container>

    ), [form.values.gender, form.values.model, form.values.version, form.values.exogen, form.values.zone]);

};

interface LoadEditorScreenProps {
    [key: string]: any;
}

export default LoadEditorScreen;
