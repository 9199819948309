import React, { useEffect, useMemo } from 'react';
import { useStore } from '@dex/bubl-ui';
import { config, AppWrapper } from '@dex/bubl-web';

import './styles/Web.scss';
import Routes from './routes/Routes';
import { apiSetToken } from '@dex/bubl-fetch';

export default function App() {

    config.init({
        siteName: "LILA+ Avatar",
    });

    const [key] = useStore("appKey", 1);

    const routeHash = Routes();

    useEffect(() => {

        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');

        if (token) apiSetToken(token);

    }, []);

    return useMemo(() => {

        return (

            <>

                <AppWrapper
                    key={key + routeHash}
                    noLogin={true}
                    loadingScreen={() => {
                        return (
                            <>
                            </>
                        )
                    }}
                />

            </>

        )

    }, [key, routeHash]);

}
