import React, { useEffect, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { softShadows, OrbitControls, Box } from "@react-three/drei";

import { ContactShadows, Icosahedron, Plane } from '@react-three/drei'
import { Vector3 } from "three";

//@ts-ignore
const SpinningMesh = ({ position, color, speed, args }) => {

    //@ts-ignore
    const mesh = React.useRef<Mesh>(null!)
    useFrame(({ clock }) => {
        mesh.current.position.y = Math.sin(clock.getElapsedTime()) + 2.5
    })

    return (
        <>
            <Icosahedron ref={mesh} args={[1, 2]} position-y={2}>
                <meshBasicMaterial color="lightblue" />
            </Icosahedron>
            <ContactShadows position={[0, 0, 0]} width={10} height={10} far={20} rotation={[Math.PI / 2, 0, 0]} />
            <Plane args={[10, 10]} position={[0, -0.01, 0]} rotation={[-Math.PI / 2, 0, 0]}>
                <meshBasicMaterial color="white" />
            </Plane>
        </>
    );
};

//@ts-ignore
const Template = function basicRayCaster(...args) {

    const [ray, setRay] = useState(null);
    const [box, setBox] = useState(null);

    useEffect(() => {

        if (!ray || !box) return;

        // console.log(ray);

        //@ts-ignore
        const intersect = ray.intersectObject(box);

        // console.log(intersect);

    }, [box, ray]);

    return (

        <>

            <Box
                onClick={(e: any) => {
                    // console.log(e);
                    console.log(e.point);
                    console.log(e.pointer);
                }}
                ref={setBox}
            >

            </Box>

            <raycaster
                //@ts-ignore
                ref={setRay}
                //@ts-ignore
                ray={[new Vector3(-3, 0, 0), new Vector3(1, 0, 0)]}
            >
            </raycaster>

        </>

    );

};

const ExampleScreen: React.FC<ExampleScreenProps> = (props) => {

    return (
        <>
            <Canvas  {...props}>

                <Template />

            </Canvas>
        </>
    )

};

interface ExampleScreenProps {
    [key: string]: any;
}

export default ExampleScreen;
