import { useRouter } from "@dex/bubl-ui";
import { config } from "@dex/bubl-web";
import { hash } from "@dex/bubl-helpers";

import protectedHandler from "./Routes.protectedHandler";
import PageNotFoundScreen from "../screens/PageNotFoundScreen/PageNotFoundScreen";
import HomeScreen from "../screens/HomeScreen/HomeScreen";
import BuildScreen from "../screens/BuildScreen/BuildScreen";
import ChooseScreen from "../screens/ChooseScreen/ChooseScreen";
import SeeScreen from "../screens/SeeScreen/SeeScreen";
import ModelScreen from "../screens/ModelScreen/ModelScreen";
import ExampleScreen from "../screens/ExampleScreen/ExampleScreen";
import ReviewScreen from "../screens/ReviewScreen/ReviewScreen";
import SaveScreen from "../screens/SaveScreen/SaveScreen";
import DemoScreen from "../screens/DemoScreen/DemoScreen";
import ReviewClothesScreen from "../screens/ReviewClothesScreen/ReviewClothesScreen";
import LoadEditorScreen from "../screens/LoadEditorScreen/LoadEditorScreen";

export default () => {

    useRouter.protectedHandler = protectedHandler;

    if (config.env === "local") {

    }

    useRouter.addRoute({
        name: "404",
        component: PageNotFoundScreen,
        link: "/404",
        options: {},
    });

    useRouter.addRoute({
        name: "Home",
        component: HomeScreen,
        link: "/",
        options: {},
    });

    useRouter.addRoute({
        name: "Build",
        component: BuildScreen,
        link: "/build",
        options: {},
    });

    useRouter.addRoute({
        name: "Choose",
        component: ChooseScreen,
        link: "/choose",
        options: {},
    });

    useRouter.addRoute({
        name: "See",
        component: SeeScreen,
        link: "/see",
        options: {},
    });

    useRouter.addRoute({
        name: "3d Model",
        component: ModelScreen,
        link: "/model",
        options: {},
    });

    useRouter.addRoute({
        name: "Review",
        component: ReviewScreen,
        link: "/review",
        options: {},
    });

    useRouter.addRoute({
        name: "Review Clothes",
        component: ReviewClothesScreen,
        link: "/review/clothes",
        options: {},
    });

    useRouter.addRoute({
        name: "Load Editor",
        component: LoadEditorScreen,
        link: "/editor",
        options: {},
    });

    useRouter.addRoute({
        name: "Drei 3d Model",
        component: ExampleScreen,
        link: "/example",
        options: {},
    });

    useRouter.addRoute({
        name: "Save Screen",
        component: SaveScreen,
        link: "/save",
        options: {},
    });

    useRouter.addRoute({
        name: "Demo Screen",
        component: DemoScreen,
        link: "/demo",
        options: {},
    });

    return process.env.NODE_ENV === "development" ? hash(useRouter.routes) : "";


};
