import React, { Suspense, useMemo, useRef } from "react";
import Container from "../../elements/Container/Container";

const ModelView = React.lazy(() => import('../../elements/ModelView/ModelView'));
const ModelCanvas = React.lazy(() => import('../../elements/ModelCanvas/ModelCanvas'));

import styles from "./DemoScreen.module.scss";

const DemoScreen: React.FC<DemoScreenProps> = (props) => {

    const modelRef = useRef();

    const gender = "Female";
    const model = "Full";
    const version = process.env.REACT_APP_AVATAR_VERSION;

    const config = {

    }

    return useMemo(() => (

        <Container>

            <Suspense fallback={<></>}>

                <ModelCanvas
                    controls={{
                        enablePan: true
                    }}
                    shadows={{
                        far: 3,
                        scale: 8,
                        rotation: [Math.PI / 2, 0, 0],
                        position: [0, -3, 0],
                    }}
                    position={"full"}
                    lights={true}
                    initialCamera={"full"}
                >

                    <ModelView
                        modelRef={modelRef}
                        path={'/model/full/' + version + '/' + gender + "-" + model + '.glb'}
                        position={[0, -3, 0]}
                        rotation={[0, 0, 0]}
                        scale={3.6}
                        config={{}}
                        loadAnimations={true}
                        loadItems={true}
                    />

                </ModelCanvas>

            </Suspense>

        </Container>

    ), []);

};

interface DemoScreenProps {
    [key: string]: any;
}

export default DemoScreen;
