import React, { Suspense } from "react";
import Container from "../../elements/Container/Container";

const ModelSee = React.lazy(() => import('../../elements/ModelSee/ModelSee'));
const ModelCanvas = React.lazy(() => import('../../elements/ModelCanvas/ModelCanvas'));

const SeeScreen: React.FC<SeeScreenProps> = (props) => {

    return (

        <Container>

            <Suspense fallback={<></>}>

                <ModelCanvas
                    // stats
                    lights
                    controls={{
                        enablePan: true,
                        // disableZoom: true,
                    }}
                    initialCamera={"full"}
                >

                    <ModelSee />

                </ModelCanvas>

            </Suspense>

        </Container>

    );
};

interface SeeScreenProps {
    [key: string]: any;
}

export default SeeScreen;
